export const data = {
  sitemapTitle: "개인정보처리방침 - 카카오 i 커넥트 라이브",
  html: `
  <section class="stackedit">
      <div class="stackedit__left">
        <div class="stackedit__toc">
          <ul>
            <li>
              <a href="#개인정보-처리방침">개인정보 처리방침</a>
              <ul>
                <li>
                  <a href="#수집하는-개인정보-항목">수집하는 개인정보 항목</a>
                </li>
                <li><a href="#수집방법">수집방법</a></li>
                <li>
                  <a href="#개인정보의-보유-및-이용기간">
                    개인정보의 보유 및 이용기간
                  </a>
                </li>
                <li><a href="#개인정보-제공">개인정보 제공</a></li>
                <li><a href="#개인정보-처리-위탁">개인정보 처리 위탁</a></li>
                <li><a href="#사용자-행동-분석">사용자 행동 분석</a></li>
                <li>
                  <a href="#제3자-제공-시-고지의무-및-책임">
                    제3자 제공 시 고지의무 및 책임
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="stackedit__right">
        <div class="stackedit__html">
          <h1 id="개인정보-처리방침">개인정보 처리방침</h1>
          <p>
            개인정보는 생존하는 개인에 관한 정보로서, 개인을 식별할 수 있는
            정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와
            용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다. 회사가 수집한
            개인정보는 다음의 목적을 위해 활용합니다.
          </p>
          <ol>
            <li>
              서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 콘텐츠
              제공, 물품배송 또는 청구서 등 발송, 본인 인증 및 구매, 요금 결재,
              요금추심
            </li>
            <li>
              회원 관리 회원제 서비스 이용에 따른 본인 확인, 개인식별, 불량회원의
              부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 가입 및 가입횟수
              제한, 본 개정 이전에 가입한 14세 미만자의 법정 대리인 본인확인, 추후
              법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등
              민원처리, 고지사항 전달
            </li>
            <li>
              마케팅 및 광고에 활용 신규 서비스(제품) 개발 및 특화, 인구통계학적
              특성에 따른 서비스 제공 및 광고게재, 접속 빈도 파악, 회원의 서비스
              이용에 대한 통계, 이벤트 등 광고성 정보 전달 (회원의 개인정보는
              광고를 의뢰한 개인이나 단체에 제공되지 않습니다.)
            </li>
          </ol>
          <h2 id="수집하는-개인정보-항목">수집하는 개인정보 항목</h2>
          <h3 id="최초-회원가입">최초 회원가입</h3>
          <ul>
            <li>사유 : 회원식별 및 최적화 된 서비스 제공</li>
            <li>필수항목 : 이메일주소</li>
            <li>선택항목 : 담당자명, 기업명, 전화번호</li>
          </ul>
          <h3 id="서비스-사용-시-수집되는-항목">서비스 사용 시 수집되는 항목</h3>
          <h4 id="기기-정보">기기 정보</h4>
          <p>
            리모트몬스터는 하드웨어 모델, 운영체제 버전, 고유 기기 식별자, 모바일
            네트워크 정보와 같은 기기별 정보를 수집합니다. 리모트몬스터는 기기
            식별자를 서비스 이용량에 연결할 수 있습니다.
          </p>
          <h4 id="로그-정보">로그 정보</h4>
          <p>
            리모트몬스터 SDK를 포함한 애플리케이션을 사용하거나 리모트몬스터를
            통해 콘텐츠를 전송할 때 리모트몬스터는 서버 로그에 특정 정보를
            자동으로 수집하고 저장합니다. 여기에는 다음이 포함됩니다.
          </p>
          <ul>
            <li>
              리모트몬스터 서비스를 사용한 방법에 대한 세부정보(예: 코덱,
              비트레이트)
            </li>
            <li>인터넷 프로토콜 주소, 기기 MAC 주소</li>
            <li>
              기기 이벤트 정보(다운로드, 시스템 활동, 하드웨어 설정, 브라우저
              유형, 브라우저 언어, 요청 날짜 및 시간, 참조 URL)
            </li>
            <li>사용자의 브라우저, 디바이스를 고유하게 식별할 수 있는 정보</li>
          </ul>
          <h4 id="쿠키-및-유사한-기술">쿠키 및 유사한 기술</h4>
          <p>
            사용자가 리모트몬스터 웹사이트를 방문할 때 회사는 다양한 기술을
            사용하여 정보를 수집하고 저장하며, 이는 쿠키 또는 유사한 기술을
            사용하여 사용자의 브라우저 또는 기기를 식별하는 것을 포함할 수
            있습니다.
          </p>
          <h2 id="수집방법">수집방법</h2>
          <p>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
          <ul>
            <li>웹 사이트를 통한 회원가입, 콘솔 로그인, 게시판</li>
            <li>생성정보 수집 툴을 통한 수집</li>
            <li>서비스 사용 중 네트워크 연결을 통한 자동 수집</li>
          </ul>
          <h2 id="개인정보의-보유-및-이용기간">개인정보의 보유 및 이용기간</h2>
          <p>
            원칙적으로 개인정보 수집 및 이용 목적이 달성된 후에는 해당 정보를
            지체없이 파기합니다.<br />
            단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간동안 보존합니다.
          </p>
          <h3 id="회원탈퇴-시-보존-개인정보">회원탈퇴 시 보존 개인정보</h3>
          <ul>
            <li>
              보존항목: 회원이 제공한 이름, 사업자등록번호, 아이디, 이메일주소,
              휴대전화번호 등
            </li>
            <li>
              보존근거: 불량 이용자의 재가입 방지, 명예훼손 등 권리침해 분쟁 및
              수사협조
            </li>
            <li>보존기간: 회원탈퇴 후 최대 1년</li>
          </ul>
          <h3 id="상거래-전자거래시-개인정보의-보관-및-기록">
            상거래, 전자거래시 개인정보의 보관 및 기록
          </h3>
          <p>
            상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계법령의 규정에
            의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간
            동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의
            목적으로만 이용하며 보존기간은 아래와 같습니다.
          </p>
          <h4 id="계약-또는-청약철회-등에-관한-기록">
            계약 또는 청약철회 등에 관한 기록
          </h4>
          <ul>
            <li>보존 이유 : 전자상거래 등에서의 소비보호에 관한 법률</li>
            <li>보존 기간 : 5년</li>
          </ul>
          <h4 id="대금결제-및-재화-등의-공급에-관한-기록">
            대금결제 및 재화 등의 공급에 관한 기록
          </h4>
          <ul>
            <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
            <li>보존 기간 : 5년</li>
          </ul>
          <h4 id="소비자의-불만-또는-분쟁처리에-관한-기록">
            소비자의 불만 또는 분쟁처리에 관한 기록
          </h4>
          <ul>
            <li>보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
            <li>
              보존 기간 : 3년 - 방문에 관한 기록(서비스 이용기록, 접속로그, 접속
              IP 정보)
            </li>
            <li>보존 이유 : 통신비밀보호법</li>
            <li>보존 기간 : 3개월</li>
          </ul>
          <h2 id="개인정보-제공">개인정보 제공</h2>
          <p>회사는 개인정보를 제3자에게 제공하지 않습니다.</p>
          <h2 id="개인정보-처리-위탁">개인정보 처리 위탁</h2>
          <p>회사는 개인정보 처리를 위탁하지 않습니다.</p>
          <h2 id="사용자-행동-분석">사용자 행동 분석</h2>
          <p>
            리모트몬스터 상품 소개, 웹 콘솔, 커뮤니티 등 웹 사이트는 서비스 품질
            개선을 위해 Google INC. (이하 “Google”)이 제공하는 분석 서비스인
            "Google Analytics"를 이용합니다. 웹 콘솔은 사용자 개인을 식별할 수
            없는 형태로 사용자의 웹 콘솔 사용행태 로그를 "Google"측 서버로
            전송하게 됩니다. 사용자는 웹 브라우저의 설정 메뉴를 통해 "Google
            Analytics"를 거부할 수 있습니다.
          </p>
          <h2 id="제3자-제공-시-고지의무-및-책임">
            제3자 제공 시 고지의무 및 책임
          </h2>
          <p>
            리모트몬스터 SDK를 이용한 애플리케이션으로 제3자에게 서비스 제공 시 위
            제시된 개인정보가 회사에게 제공될 수 있음을 고지하여야 하며,
            애플리케이션 제공자는 제3자에 대한 개인정보 보호에 최선을 다하여야
            합니다. 제3자에 대한 모든 책임 및 분쟁은 애플리케이션 제공자에게
            있습니다.
          </p>
        </div>
      </div>
    </section>`
};
